<template>
  <!--<img src="./assets/logo.png">-->
  
  <div id="app1">
    <!--<mynavbar/>
    <Login />
    <el-button type="primary">el-button</el-button>
    <Home /> -->
    <router-view></router-view>
  </div> 
  
</template>

<script>
//import {getCurrentInstance} from "vue";
// import global from '@/global'
// import {QueryTerminal} from './api/data.js';
// import Home from './views/Home.vue'
// import mynavbar from './components/AppComponents/Navbar.vue'
// import Login from './components/AppComponents/login.vue'
export default {
  // name: 'App',
  // components: {
  //   Home,
  //   mynavbar,
  //   Login
  // }
     
  created(){
    
      
      
  },
  mounted(){
      // window.addEventListener('beforeunload', ()=>{
      //     sessionStorage.setItem('state', JSON.stringify(global));
      // });
      // window.addEventListener("load", () => {
      //   global = JSON.parse(sessionStorage.getItem('state'));
      //   sessionStorage.clear();
      //   });
      
      
    },
    methods:{
      
    }
  }

</script>

<style lang="less" scoped>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
