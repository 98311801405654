import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import MyConsole from '../components/MyConsole.vue'
import MarketPackage from '../components/MarketPackage.vue'
import main from '../components/main.vue'
//import FirstPart from '../components/FirstPart.vue'
import Papers from '../components/Papers.vue'

const routes = [
  {//一级路由
    path: '/',
    name: 'main',
    component: main
  },
  {
    path:'/download',
    name:'download',
    component:() => import('../components/download.vue')
  },
  
  {
    path:'/price',
    name:'price',
    component:() => import('../components/price.vue')
  },
  {
    path:'/sell',
    name:'sell',
    component:() => import('../components/sell.vue')
  },
  {
    path:'/about',
    name:'about',
    component:() => import('../components/about.vue')
  },
  {
    path:'/index',
    name:'index',
    component: () => import('../components/AppComponents/Navbar.vue'),
    children:[ //二级路由
      
      {
        path:'/MyDo',
        name:'MyDo',
        component:() => import('../components/MyDo.vue')
      },
      {
        path: '/Papers',
        name: 'Papers',
        component: Papers,
        // meta: {
        //   requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
        // }
      },
      {
        path: '/MarketPackage',
        name: 'MarketPackage',
        component: MarketPackage
      },
      {
        path: '/MyConsole',
        name: 'MyConsole',
        component: MyConsole
      }
    ]
  },
  {
    path: '/TestPaperResult',
    name: 'TestPaperResult',
    component:() => import('../components/AppComponents/TestPaperResult.vue')
  },
  {
    path:'/TestPaper',
    name:'TestPaper',
    component:() => import('../components/AppComponents/TestPapers.vue')
  },
  {
    path:'/TestPaperOther',
    name:'TestPaperOther',
    component:() => import('../components/AppComponents/TestPaperOther.vue')
  },
  
  
  
]

const router = createRouter({
  history: createWebHashHistory(),
  base:'/dist/',     //打包项目的根目录
  routes
})

export default router
