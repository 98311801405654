const machineId="CTS";
const canUseNums=0;
const Machine_Paper="CTS_DOING_PAPER";

//将当前正在做的试卷写入本地存储
var setMyDoingPaper = (obj)=>{
    localStorage.setItem(Machine_Paper, JSON.stringify(obj));
}

var getMyDoingPaper =()=>{
    var obj = JSON.parse(localStorage.getItem(Machine_Paper));
    return obj;
}

var clearMyDoingPaper =()=>{
    localStorage.removeItem(Machine_Paper);
}

export default{
    machineId,
    canUseNums,
    setMyDoingPaper,
    getMyDoingPaper,
    clearMyDoingPaper
}

