<template>
  <div style="display: table-cell; min-width: 100px">
    <One @refreshData="refreshData($event)"/>
  </div>
  <div v-if="apps.length>0">
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col
        :span="6"
        v-for="(item, index) in apps"
        :key="item.id"
        class="el-col"
      >
        <el-card class="el-card" :key="index" onclick="">
          <template v-slot:header class="clearfix">
            <span><font size="3">{{ item.appname }}</font></span>
          </template>
          <div>
            <div class="text item">
              <div>
                <span>题目数量&nbsp;<font color="blue" size="3">{{ item.question_num }}</font></span>
                <span style="margin-left:10px;">总分&nbsp;<font color="blue" size="3">{{ item.marks }}</font></span> 
                <span style="margin-left:10px;">时长&nbsp;<font color="blue" size="3">{{ item.time_length }}</font>&nbsp;分钟</span>
              </div>
              <div style="margin-top:5px;">
                <span>试卷年份：{{ item.dt.split(' ')[0] }}</span>
                <span style="margin-left:10px;">合格分&nbsp;<font color="blue" size="3">{{ item.qualified_score }}</font></span>
              </div>
              <div style="margin-top:5px;">
                <span><font color="gray" size="2">{{ item.allDo }}人已做</font></span>
                <span style="margin-left:20px;"><font color="gray" size="2">已做{{ item.myDo }}次</font></span> 
                <img v-if="item.isFree==1" style="float:right;" src="../assets/free.png">
              </div>
            </div>
            
            
            
            <div >
              <!-- <el-button type="primary" @click="doTest(item.id,item.showType)">开始做题</el-button> -->
              <el-button type="primary" @click="doModal(item.id,item.showType);">开始做题</el-button>
              <span v-if="item.running==true" style="float:right;"><font color="green" size="3"><b>正在考试</b></font></span>
            </div>
            
          </div>
        </el-card>
      </el-col>
      
    </el-row>
    <el-row style="margin-top: 60px;text-align: center;">
        <el-col :span="3">
          <div>
            <div class="maskP" v-if="showModal" @click="showModal=false"></div>
            <div class="popP" v-if="showModal" style="display:inline-block;text-align:center;">
              <div><img src="../assets/question.png" /></div>
              <div style="margin-top:5px;"><span>您的余额不足，请购买套餐后使用</span></div>
              <div style="margin-top:5px;"><span>是否立即购买套餐？</span></div>
              <div style="margin-top:20px;">
                <el-button  type="success" style="width:100px;" @click="doBuy();">确定</el-button>
                <el-button  type="info" style="width:100px;margin-left:50px;" @click="showModal=false">取消</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
  </div>
  <div v-else>
      <el-empty description="暂时没有试卷"></el-empty>
  </div>
</template>

<script>
import One from "./ProductType.vue";
import {QueryAvailableTestPaper,FormationMyTest} from '../api/data.js';
import global from '@/global';

export default {
  name: 'father',
  data() {
    return {
      apps: [],
      showModal: false,
      QueryDetail:'测试二维码',
      count: ''
    };
  },
  methods: {
    
    doCancel(){
      
      this.showModal=false;
    },
    doModal(testId,showType){
        let obj = {
        userId:global.machineId,
        testPaperId:testId
      } 
      var param="width="+window.screen.width * window.devicePixelRatio+",height="+window.screen.height * window.devicePixelRatio+",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no";
      
      let that = this; //很重要
      var running = global.getMyDoingPaper();
      
      //if (running!=null&&running.id!=undefined&&running.testId!=undefined)
      if (running!=null&&running!=undefined&&(running.checked==null||running.checked==undefined))
      {
          if (running.showType==1646) //分类展示
            {
               
              const neww = that.$router.resolve({name: 'TestPaper', params: {id: running.id,testId:running.testId}});
              window.open(neww.href,"云思模拟考场",param);
            }
            else //1647随机展示 
            {
                const neww = that.$router.resolve({name: 'TestPaperOther', params: {id: running.id,testId:running.testId}});
                window.open(neww.href,"云思模拟考场",param);
              
            }
      }
      else
      {
          FormationMyTest(obj).then(function(res){
          if (res.flag==2) //需要充值
          {
              that.showModal = true;
          }
          else if (res.flag==1) //正常生成试卷
          {
            //写入到本地存储
            running = new Object();
            running.id = testId;  //试卷pkid
            running.testId = res.data; //用户测试实例pkid
            running.showType = showType; //显示类型
            global.setMyDoingPaper(running);
            if (showType==1646) //分类展示
            {
                
                const neww = that.$router.resolve({name: 'TestPaper', params: {id: testId,testId:res.data}});
                window.open(neww.href,"云思模拟考场",param);
            }
            else //1647随机展示 
            {
                const neww = that.$router.resolve({name: 'TestPaperOther', params: {id: testId,testId:res.data}});
                window.open(neww.href,"云思模拟考场",param);
                
            }
              
          }
          
        });
      }
        
    },
    doTest(testId,showType){   
      //测试
      let obj = {
        userId:global.machineId,
        testPaperId:testId
      } 
      let that = this; //很重要
      var running = global.getMyDoingPaper();
      if (running!=null)
      {
          if (running.showType==1646) //分类展示
            {
                that.$router.push({
                  path:'/TestPaper',
                  name:'TestPaper',
                  params:{
                    id:running.id, //试卷pkid
                    testId:running.testId //用户测试实例pkid
                  }
                });
            }
            else //1647随机展示 
            {
                that.$router.push({
                  path:'/TestPaperOther',
                  name:'TestPaperOther',
                  params:{
                    id:running.id, //试卷pkid
                    testId:running.testId //用户测试实例pkid
                  } 
                });
            }
      }
      else
      {
          FormationMyTest(obj).then(function(res){
          if (res.flag==2) //需要充值
          {
              that.showModal = true;
          }
          else if (res.flag==1) //正常生成试卷
          {
            if (showType==1646) //分类展示
            {
                that.$router.push({
                  path:'/TestPaper',
                  name:'TestPaper',
                  params:{
                    id:testId,
                    testId:res.data
                  }
                });
            }
            else //1647随机展示 
            {
                that.$router.push({
                  path:'/TestPaperOther',
                  name:'TestPaperOther',
                  params:{
                    id:testId,
                    testId:res.data
                  }
                });
            }
              
          }
          
        });
      }
      
    },
    refreshData(msg){
      this.apps = []; //先清空数组
      //向后台查询试卷清单
      let obj = {
        userId:global.machineId,
        ministries:msg
      }
      let that = this; //很重要
      //获取是否正在考试的试卷
      var running = global.getMyDoingPaper();
      QueryAvailableTestPaper(obj).then(function(res){
        for(var i=0;i<res.data.length;i++)
        {
          var obj = new Object();
          obj.id = res.data[i].pkid;
          obj.appname = res.data[i].test_name;
          obj.marks = res.data[i].marks;
          obj.qualified_score = res.data[i].qualified_score;
          obj.question_num = res.data[i].question_num;
          obj.time_length = res.data[i].time_length;
          obj.difficulty_level = res.data[i].difficulty_level;
          obj.dt = res.data[i].dt;
          obj.allDo = res.data[i].allDO;
          obj.myDo = res.data[i].myDO;
          obj.isFree = res.data[i].isFree;
          obj.showType = res.data[i].show_type;
          if (running!=null&&obj.id==running.id)
          {
            obj.running = true;
          }
          else
            obj.running = false;
          that.apps.push(obj);
        }
      });
      
    },
    doBuy() {
          //触发界面退出
          this.showModal = false;
          //测试打开界面，并传递一个参数
          this.$router.push({
            path:'/MarketPackage',
            name:'MarketPackage'
          });
    },
  },
  components: {
    One,
  },
};
</script>

<style type="text/css">

.item {
  margin-bottom: 5px;
}

.text {
  width: 100%;
  font-size: 14px;
  color: #667083;
}

.el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 5px;
  margin-left:5px;
  background-color:bisque;
  /*transition: all .5s;*/
}
.el-card:hover {
  margin-top: -5px;
}
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 20px;
}

 .maskP {
  background-color: rgb(199, 187, 187);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
    .popP {
  background-color: #fff;
  position: fixed;
  top: 300px;
  left: 500px;
  width: 400px;
  height:200px;
  z-index: 2
}


</style>
