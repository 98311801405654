import axios  from "axios"
import qs from 'qs'
import store from '../store/index'
import { Message } from "element3"

const service=axios.create({
    timeout:5000, //超时时间
    baseURL:"http://api.sellod.cn/",    //请求接口时就不用写前面，自动补齐
    transformRequest:data=>qs.stringify(data)  //post请求参数处理，防止post请求跨域
});

//http request拦截器
service.interceptors.request.use(config=>{
    //如果存在jwt，则将jwt添加到每次请求之中
    return config;
},err=>{
    return err;
});

//http response拦截器
service.interceptors.response.use(response=>{
    //接收返回数据
    const res = response.data;
    //判断返回数据是否存在状态flag和错误信息
    if (!res.flag){  //||!res.data分数有可能返回0分，所以此处不能加此判断
        return showMessage('响应数据格式错误');
    }
    //判断状态code是否为指定数值(0)
    if (res.flag==0){
        return showMessage(res.data);
    }
    return res;
},err=>{
    return showMessage(err.Message);
});

//封装错误提示信息
function showMessage(msg){
    Message({
        message:msg,  //错误提示信息
        type:'error', //显示类型
        duration:3*1000 //展示时间
    });
    return Promise.reject();
}

export default service;