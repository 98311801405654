<template>
<DIV id="bdy4">
    <DIV id="tmain2" :style="height">
      <DIV id="tm2_1"><img src="../images/32.png" /></DIV>
      <DIV id="cont_left" :style="height_left">
        <div style="text-align:center;">
          <img src="../assets/user.png" />
          <span ><font size="3"> {{mid}}</font></span>
        </div>
        </DIV>
        <DIV id="cont_right">
          <div style="margin-top:5px;margin-bottom:30px;">
            <el-badge :value="count" class="item"><el-tag type="danger" effect="dark"><font size="4">可做试卷数量：{{count}} 套</font><font size="2" color="blue">【免费除外】</font></el-tag></el-badge>
          </div>
        <font size="4" style="margin-bottom:10px;"><i class="el-icon-edit">交易数据：</i></font>
        <div v-if="subs.length>0">
          <el-table
            :data="subs"
            style="width: 100%">
              <el-table-column
                prop="pre_no"
                label="订单编号"
                width="100">
              </el-table-column>
              <el-table-column
                prop="check_dt"
                label="交易时间"
                width="180">
              </el-table-column>
              <el-table-column
                prop="times"
                label="购买试卷数(套)"
                width="140">
              </el-table-column>
              <el-table-column
                prop="moneys"
                label="订单金额(元)"
                width="130">
              </el-table-column>
              <el-table-column
                prop="market_name"
                label="套餐名称"
                width="180">
              </el-table-column>
          </el-table>


          
        </div>
        <div v-else>
            <el-empty description="暂时没有订单"></el-empty>
        </div>
          </DIV>
  
  <DIV id="tm2_4" class="blu" style="overflow: hidden; height: 220px;">
            <DIV id="m4_2_new">
              <img
              width="60"
                      height="60"
                      border="0" 
              src="../images/logomin.png"/>
              
              
            </DIV>
            <div style="text-align:center;"><font size="2">云思模拟考场，最好的帮手！</font></div>
          </DIV>
  </DIV>
</DIV>
</template>

<script>
import "../css/CSS.css";
import {QueryMySubs} from '../api/data.js';
import global from '@/global';

export default {
  data() {
    return {
      subs: [],
      count: '',
      mid:'',
      height: {
            height: window.innerHeight-60 + 'px'
        },
      height_left: {
          height: window.innerHeight-292 + 'px'
      },
    };
  },
  mounted(){
    this.querySubs();
  },
  methods:{
    dosmo(){
      //window.open("/html/index.htm");
      this.$router.push({
                path:'/main',
                name:'main'
              });
    },
    querySubs(){
      let that = this;
      this.mid = global.machineId;
      let obj = {
        userId:global.machineId
      }
      QueryMySubs(obj).then(function(res){
        that.count = res.data[0].max_nums-res.data[0].use_nums;
        //填充定单信息
        for (var i=0;i<res.data[0].arrSubs.length;i++)
        {
            var sub = new Object();
            sub.check_dt = res.data[0].arrSubs[i].check_dt;
            sub.moneys = res.data[0].arrSubs[i].moneys;
            sub.pre_no = res.data[0].arrSubs[i].pre_no;
            sub.times = res.data[0].arrSubs[i].times;
            sub.market_no = res.data[0].arrSubs[i].market_no;
            sub.market_name = res.data[0].arrSubs[i].market_name;
            sub.dt_length = res.data[0].arrSubs[i].dt_length;
            that.subs.push(sub);
        }
      });

    }
  },
  components:{
    
}
}

</script>