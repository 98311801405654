<template>

  <div>
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col
        :span="6"
        v-for="(item, index) in apps"
        :key="item.id"
        class="el-col"
      >
        <el-card class="el-cards" :key="index" onclick="">
          <template v-slot:header class="clearfix">
            <span><font size="4">{{ item.market_name }}</font></span>
          </template>
          <div>
            <div class="text item">
              <div>
                <span style="margin-left:10px;">¥&nbsp;<font color="blue" size="4">{{ item.moneys }}</font></span>
              </div>
              <div>
                <span style="margin-left:10px;"><font size="3">享有</font>&nbsp;<font color="blue" size="3">{{ item.times }}套试卷</font></span> 
              </div>
              <div>
                <span style="margin-left:10px;"><font size="3">不限科目</font></span> 
              </div>
            </div>
            
            
            
            <div >
              <el-button type="primary" @click="dos(item.market_name,item.id,item.moneys,item.times);">购买</el-button>
              
            </div>
            
          </div>
        </el-card>
      </el-col>
      
    </el-row>
    
    <!--扫码支付-->
    <div>
      <div class="maskS" v-if="showModal" ></div>
      <div class="popS" v-if="showModal" style="display:inline-block;text-align:center;">
        <div class="payDiv" id="divTitle"><span><font size="3">购买：*</font></span><img style="float:right;" @click="doCancel();" src="../assets/close_red.png"/></div>
        <div id="divMoney"><span><font size="3" color="gray">支付金额：<font size="3" color="red">¥</font> <font size="4" color="red"><b>*</b></font></font></span></div>
        <div><canvas id="canvas"></canvas></div>
        <div><font size="3">请用支付宝扫码支付</font></div>
        <div style="margin-top:20px;"><font size="4" color="blue"><font size="2">还剩&nbsp;</font>{{count}}<font size="2">&nbsp;秒</font></font></div>
        <div style="margin-top:30px;"><font color="blue">测试中建议使用“云思助手”辅助工具</font></div>
      </div>
  </div>
  </div>
</template>

<script>
import {QueryMarketPackages,QueryPayNo,QueryWebMyPay} from '../api/data.js';
import QRCode from 'qrcode'     //引入生成二维码组件
import global from '@/global';

export default {
  name: 'father',
  data() {
    return {
      apps: [],
      showModal: false,
      QueryDetail:'',
      count: '',
      timeOut: null,
      payno:''
    };
  },
  mounted(){
      this.refreshData();
  },
  methods: {
    refreshData(){
      this.apps = []; //先清空数组
      //向后台查询试卷清单
      let obj = {
        userId:global.machineId
      }
      let that = this; //很重要
      QueryMarketPackages().then(function(res){
        for(var i=0;i<res.data.length;i++)
        {
          var obj = new Object();
          obj.id = res.data[i].pkid;
          obj.market_name = res.data[i].market_name;
          obj.moneys = res.data[i].moneys;
          obj.times = res.data[i].times;
          that.apps.push(obj);
        }
      });
      
    },
    setTime(obj){
      const TIME_COUNT = 90; //默认90秒的支付时间
      obj.count = TIME_COUNT;
      //倒计时功能
      let objj = {
        userId:global.machineId,
        payno:obj.payno
      }
      obj.timeOut = setInterval(() => {
        //到后台查询是否接收到付款成功信息
        QueryWebMyPay(objj).then(function(res){
            //触发界面退出
            if (res.data=="SUCCESS")
            {
                //触发界面退出
                  obj.showModal = false;
                  clearInterval(obj.timeOut);
                  obj.timeOut = null;
                //跳转到我的订单界面
                  obj.$router.push({
                      path:'/MyConsole',
                      name:'MyConsole'
                    });
            }
            else
            {
                if (obj.count > 0 && obj.count <= TIME_COUNT)
                    obj.count--;
                else
                {
                  //触发界面退出
                  obj.showModal = false;
                  clearInterval(obj.timeOut);
                  obj.timeOut = null;
                  
                }
            }
        });
        
            
      }, 1000);
    },
     dos(market_name,id,money,times){
      this.showModal=true;
      let obj = {
        userId:global.machineId,
        marketId:id,
        moneys:money,
        times:times
      }
      
      let that = this;
      //查询预支付定单号
        QueryPayNo(obj).then(function(res){
          that.QueryDetail = res.data;
          that.payno = res.payno;
          that.$nextTick(() => {
            document.getElementById('divMoney').getElementsByTagName("b")[0].innerHTML=money;
            document.getElementById("divTitle").getElementsByTagName("span")[0].innerHTML="<font size='3'>购买："+market_name+"</font>";
            var canvas = document.getElementById('canvas');
            QRCode.toCanvas(canvas, that.QueryDetail, function (error) {
              if (error) console.error(error)
                //console.log('已生成二维码!');
                that.$options.methods.setTime(that);
              });
        
          }); 
         
        });
      
    },
    doCancel(){
      if (this.timeOut!=null)
      {
          clearInterval(this.timeOut);
          this.timeOut = null;
      }
      this.showModal=false;
    },
  },
  components: {
   
  },
};
</script>

<style type="text/css">
.all {
  margin-top: -30px;
  word-break: break-all;
  height: 100%;
}
.mid {
  margin-top: 25%;
  height: 50%;
}
.mid_item {
  justify-content: center;
  align-items: center;
}
.item {
  margin-bottom: 5px;
}

.text {
  width: 100%;
  font-size: 14px;
  color: #667083;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.el-cards {
  min-width: 100%;
  height: 100%;
  margin-right: 10px;
  margin-top:10px;
  margin-left:10px;
  background: -webkit-linear-gradient(top,rgb(238, 234, 222),rgb(90, 209, 61));
  /*transition: all .5s;*/
}
.el-cards:hover {
  margin-top: -5px;
}
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 20px;
}

.maskS {
  background-color: rgb(173, 154, 154);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 180
}
    .popS {
  background-color: #fff;
  position: fixed;
  top: 200px;
  left: 500px;
  width: 400px;
  height:400px;
  z-index: 210
}

.licls {
  list-style: none;
  display: inline-block;
  margin: 5px 5px 10px 10px;
  color:rgb(60, 125, 245);
}

.licls a:hover {
  background-color: rgb(40, 218, 85);
  border-radius: 1px;
  cursor: pointer;
}

.payDiv{
text-align:center;
vertical-align: middle;
line-height: 32px;
}

.payDiv img{
  width: 20px;
  height: 20px;
}

.payDiv img:hover{
  background-color: rgb(163, 165, 163);
  border-radius: 1px;
  cursor: pointer;
}

#canvas{
  width:400;
  height:400;
}
</style>
