import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import QRCode from 'qrcode'    //定义生成二维码组件
//import axios from 'axios' //引入axios，用于发送请求
//import qs from 'qs'
import global from '@/global'
import {QueryTerminal} from './api/data.js';



const app = createApp(App)
//配置全局变量
//app.config.globalProperties.$machineId = "CTS"; //终端Id
//app.config.globalProperties.$canUseNums = 0; //可使用次数
//app.prototype.$ajax = axios; //配置axios
// //配置跨越：在/config/index.js中会重写跨域路径，若前缀带有"api"的请求，则会进行跨域访问
// app.prototype.Host = 'api';
// //阻止启动生产消息，减少机器运行开销
// app.config.productionTip = false
// //使每次请求都会带一个 /api 前缀 
// //axios.defaults.baseURL = '/api'
//app.prototype.$qs=qs;

installElementPlus(app)
new initGlobaleData();



//设置全局路由守卫
// router.beforeEach((to,from,next)=>{
//     //window.document.title=to.meta.title;
//     //if(to.path!='/login' && to.path!='/register')
//     //sessionStorage.setItem('referrer',from.path) //储存来源路由
//     //alert('请登录')
//     //next({path:'/login'})}else{next()}
    
//     if(to.meta.requireAuth == true)
//     {  //需要登录权限进入的路由
//       if(store.state.token)
//       {  //取到登录信息
//          next()
//       }
//       else if(sessionStorage.getItem('isLogin'))
//       {  //得到登录信息，进行下一步
//         next();
//       }
//       else
//       {
//           //next({name:'Login'});
//           next({
//               path:'/Login',
//               query:{
//                   redirect:to.fullPath //把要跳转的页面路径作为参数传到登录页面
//               }
//           })
//       }
//     }
//     else
//     {  //不需要登录权限
//        next();
//     }
//   });

  function initGlobaleData()
  {
    const Machine_Key="CTS_MACHINE_ID";
    //首先查看本地是否已经存在，如果存在，则获取，并提交给后台比较，如果不存在，则向后台获取一个
    //localStorage.clear();
    var mchInfo = JSON.parse(localStorage.getItem(Machine_Key));
    if (mchInfo==null||mchInfo==undefined)
    {
       //调用后台，生成一个机器ID号
        mchInfo ={
         mchId:"",
         business_type:"1314"
       }
       
    }
    else
       mchInfo.business_type = "1314";
    //向后台查询终端信息
    let that = this; //很重要
    
    QueryTerminal(mchInfo).then(function(res){
        // if (mchInfo.mchId=="")
        // {
          //写入本地存储
          mchInfo.mchId = res.data[0].acct;
          localStorage.setItem(Machine_Key, JSON.stringify(mchInfo));
        // }
        // else
        //   mchInfo.mchId = res.data[0].acct;
        global.machineId = mchInfo.mchId;
        global.canUseNums = res.data[0].max_nums - res.data[0].use_nums;
        //getCurrentInstance().appContext.config.globalProperties.$machineId = mchInfo.mchId;
        //getCurrentInstance().appContext.config.globalProperties.$canUseNums = cnt;
        app.use(store).use(router).mount('#app')
      });
  }