<template>
  <HEAD>
    <META http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <META http-equiv="X-UA-Compatible" content="IE=7" />
    <meta http-equiv="Cache-Control" content="no-transform" />
    <meta http-equiv="Cache-Control" content="no-siteapp" />
    <title>云思shat软件家族v1.0 - 云思shat软件家族下载官方网站</title>
    <META
      name="description"
      content="云思shat软件家族,云思shat软件家族v12.8,云思shat软件家族软件,云思助手下载软件"
    />
    <META
      name="keywords"
      content="使用云思助手工具软件,选择试卷"
    />
    <LINK HREF="../ico/logo.ico" REL="shortcut icon" />
  </HEAD>
  <BODY>
    <div id="bdy01">
    <div id="bdy02">
    <div id="head" class="w12">
      <div id="homepage" title="云思助手官方网站--首页"></div>
      <div style="padding-top: 40px"
        ><strong>全国统一服务热线：17347318127</strong></div
      >
    </div>

    <div id="navv">
      <div id="nav">
        <div class="nav3"><a href="/dist/index.html">首 页</a></div>
        <div class="nav2"><img src="../images/04.gif" /></div>
        <div class="nav1"><A href="http://www.sellod.cn"  @click="go_link();">模拟考场</A></div>
        <div class="nav2"><img src="../images/04.gif" /></div>
        <div class="nav1"><A href="/dist/index.html#/download">下载中心</A></div>
        <div class="nav2"><img src="../images/04.gif" /></div>
        <div class="nav1"><A href="/dist/index.html#/price">软件价格</A></div>
        <div class="nav2"><img src="../images/04.gif" /></div>
        <div class="nav1"><A href="/dist/index.html#/sell">购买方式</A></div>
        <div class="nav2"><img src="../images/04.gif" /></div>
        <div class="nav1"><A href="/dist/index.html#/about">联系我们</A></div>
        <div class="nav2"><img src="../images/04.gif" /></div>
      </div> </div
    ><div id="downimg">

    <div id="product_bdy02">
      
      <div class="productDownHtop">
        <!--<div class="productDownHtopl">
          <div class="productDownHtopl_1"> &nbsp;</div>
          
        </div>-->
        <div class='demo'>
        <vue3VideoPlay class='video' v-bind="options" poster='http://api.sellod.cn/upload/load.png'/>
        </div>
      </div>
      <!--<div class="productDownHbottom">
        <div class="productDown" style="width: 951px; height: 78px">
          <div class="pDownBtn">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b> &nbsp; </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <A
              title="云思助手 下载"
              href="/dist/index.html#/download"
            >
              <img
                src="../images/sum_25.png"
                id="down2011"
                alt="下载地址"
                border="0" /></A
          ></div>

          <div class="pDownSum">&nbsp;</div>
        </div>
      </div>-->
    </div>

    <div id="main" style="background:#f0f8fc;">
      <div id="m2" >
        <DL style="float: left">
          <DT class="titile01">
            <UL>
              <LI class="titile01_01">
                <H1>云思SHAT软件家族</H1
                ><font color="#FF0000"><b>产品介绍</b></font></LI
              >
              <LI class="titile01_02"></LI>
            </UL>
          </DT>
          <DD class="productDetail">
            <div class="productTexing">
              <div class="productTexingImg">
                <div class="productTexingImg1">
                  <img
                    src="../gn/cta.png"
                    width="60"
                    height="60"
                    border="0"
                /></div>
              </div>

              <div class="productTexingBox">
                <div class="productTexingTitle">1、云思助手（CTA）</div>
                简洁的操作，支持提取文字，支持招投标、政府采购等规范快速定位查询；支持各种题库查询。
              </div>

              <div class="productTexingImg">
                <div class="productTexingImg1">
                  <a href="http://www.sellod.cn" @click="go_link();"
                    ><img
                      src="../gn/cts.png"
                      width="60"
                      height="60"
                      border="0"
                    />
                  </a>
                </div>
              </div>

              <div class="productTexingBox1">
                <div class="productTexingTitle"
                  ><a href="http://www.sellod.cn" @click="go_link();">2、云思模拟考场（CTS）</a></div
                >

                <a href="http://www.sellod.cn" @click="go_link();"
                  >智能组卷，快速提高应试者知识点掌握能力；支持错题展现。</a
                ></div
              >

              <div class="productTexingImg">
                <div class="productTexingImg1">
                  <img
                    src="../gn/cth.png"
                    border="0"
                    width="60"
                    height="60"
                  />
                </div>
              </div>

              <div class="productTexingBox">
                <div class="productTexingTitle">3、云思互助（CTH）</div>

                支持远程协助，支持桌面共享，协同高效办公。
              </div>

              <div class="productTexingImg">
                <div class="productTexingImg1">
                  <img
                    src="../gn/ctg.png"
                    border="0"
                    width="60"
                    height="60"
                  />
                </div>
              </div>

              <div class="productTexingBox1">
                <div class="productTexingTitle">4、云思勾选（CTT）</div>

                采用机器视觉AI技术，支持定义重复性的操作，代替人工完成系统录入操作。
              </div>
            </div>
          </DD>

          <div style="clear: both"></div>
          <DD class="titile01" style="margin-top: 100px"
            ><UL
              ><LI class="titile01_01">合作伙伴 </LI>
              <LI class="titile01_02"></LI> </UL
          ></DD>
          <DD class="productDetail" style="padding-top: 10px">
            <UL style="padding-left: 30px">
              <LI class="links">
                <a target="_blank" href="http://www.crsky.com/">
                  非凡软件站</a
                ></LI
              >
              <LI class="links">
                <A target="_blank" href="http://download.enet.com.cn/"
                  >硅谷动力</A
                ></LI
              >
              <LI class="links">
                <A target="_blank" href="http://itpx.eol.cn/"
                  >中国教育在线</A
                ></LI
              >
              <LI class="links">
                <A target="_blank" href="http://xiazai.zol.com.cn/"
                  >中关村下载</A
                ></LI
              >
              <LI class="links">
                <A target="_blank" href="http://tech.sina.com.cn/down/"
                  >新浪下载</A
                ></LI
              >

              <LI class="links">
                <A target="_blank" href="http://download.enet.com.cn/"
                  >计世网</A
                ></LI
              >

              <LI class="links">
                <a target="_blank" href="http://www.pcpop.com/">泡泡网</a></LI
              >
              <LI class="links">
                <A target="_blank" href="http://download.it168.com/"
                  >IT168</A
                ></LI
              >
              <LI class="links">
                <a target="_blank" href="http://www.onlinedown.net/"
                  >华军软件园</a
                ></LI
              >
              <LI class="links">
                <A target="_blank" href="http://pcedu.pconline.com.cn/"
                  >太平洋软件</A
                ></LI
              >
            </UL>
          </DD>
        </DL>
      </div>
      <div id="m3">
        <div id="m3_02" style="margin-top: 25px"> </div>

        <div id="m3_02" style="margin-top: 15px">
          <UL class="m3_01_title">
            <LI class="m3_01_title01"><H2>客服中心</H2></LI>
          </UL>
          <UL id="m3_02_body">
            <LI id="m3_02_bodyimg2"> </LI>

            <LI id="m3_02_bodyimg"
              ><img src="../images/90.gif" width="50" height="50" border="0"
            /></LI>
            <LI class="m3_02_bodyfont2">客服小云 QQ:896216279</LI
            ><LI class="m3_02_bodyfont2"
              ><a
                target="blank"
                href="tencent://message/?uin=896216279&Site=软件客服&Menu=yes"
                ><img
                  src="../ico/pa.gif"
                  alt="896216279"
                  hspace="2"
                  border="0"
                  align="absmiddle" /></a
            ></LI>
            <div style="clear: both"></div>
            <LI class="m3_02_bodyfont2"></LI>
            <LI id="m3_02_bodyimg"
              ><img src="../images/90.gif" width="50" height="50" border="0"
            /></LI>
            <LI class="m3_02_bodyfont2">客服Cessary QQ:382599652</LI
            ><LI class="m3_02_bodyfont2"
              ><a
                target="blank"
                href="tencent://message/?uin=382599652&Site=软件客服&Menu=yes"
                ><img
                  src="../ico/pa.gif"
                  alt="382599652"
                  hspace="2"
                  border="0"
                  align="absmiddle" /></a
            ></LI>
            <div style="clear: both"></div>
            <LI class="m3_02_bodyfont2"></LI>

            <LI id="m3_02_bodyimg3"></LI>
            <LI id="m3_02_bodyimg"
              ><img
                src="../images/89.gif"
                width="50"
                height="50"
                border="0"
            /></LI>
            <LI class="m3_02_bodyfont2">小云 QQ:896216279</LI>
            <LI class="m3_02_bodyfont2"
              ><a
                target="blank"
                href="tencent://message/?uin=54333222&Site=云思SHAT软件家族&Menu=yes"
                ><img
                  src="http://wpa.qq.com/pa?p=1:54333222:1"
                  hspace="2"
                  border="0"
                  align="absmiddle" /></a
            ></LI>
            <LI class="m3_02_bodyfont2"> </LI>
            <LI id="m3_02_bodyimg4"></LI>
            <LI id="m3_02_bodyimg"
              ><img
                src="../images/87.gif"
                width="60"
                height="60"
                border="0"
            /></LI>
            <LI class="m3_02_bodyfont2">&nbsp;&nbsp;</LI>
            <LI class="m3_02_bodyfont2">咨询热线：17347318127</LI>

            <LI class="m3_02_bodyfont2"> </LI>
            <LI class="m3_02_bodyfont3"
              ><img src="../ico/94.gif" width="8" height="7" />
              客服在线时间：周一至周日 8:30~24:00</LI
            >
            <div style="clear: both"></div>
          </UL>
          <div><img src="../images/17.gif"/></div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>

    <div id="tm2_4" class="blu" style="overflow: hidden; height: 220px">
      <div id="m4_2_new">
        ICP许可证编号：<a href="https://beian.miit.gov.cn" target="_blank"
          >湘ICP备2021008170号-1</a
        >
        © 2021-2025 sellod.cn 版权所有
      </div>
    </div>
	</div>
	</div>
	</div>
  </BODY>
</template>

<script>
import "../css/style.css";

import 'vue3-video-play/dist/style.css' // 引入css
import vue3VideoPlay from 'vue3-video-play' // 引入组件
import { reactive,toRefs } from 'vue';


export default {
  components: {
 vue3VideoPlay
 },
 props:['video_url','poster'],
 setup(props){
   let data=reactive({
     options:{
        width: '980px', //播放器高度
        height: '552px', //播放器高度
        color: "#409eff", //主题色
        title: '云思助手操作视频', //视频名称
        src: 'http://api.sellod.cn/upload/cta.mp4', //视频源
        muted: false, //静音
        webFullScreen: false,
        speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
        autoPlay: true, //自动播放
        loop: true, //循环播放
        mirror: false, //镜像画面
        ligthOff: false,  //关灯模式
        volume: 0.3, //默认音量大小
        control: false, //是否显示控制
        controlBtns:['audioTrack', 'quality', 'speedRate', 'volume', 'setting', 'pip', 'pageFullScreen', 'fullScreen'], //显示所有按钮,
        poster:'http://api.sellod.cn/upload/load.png'
     }
  
});
  return {
    ...toRefs(data)
  }
 },
  
  methods:{
    go_link(){
      var param="width="+window.screen.width * window.devicePixelRatio+",height="+window.screen.height * window.devicePixelRatio+",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no";
      const neww = this.$router.resolve({name: 'Papers', params: {}});
      window.open(neww.href,"云思模拟考场",param);
    }
  }

};
</script>

<style>
.demo{
    display: inline-block;
    width: 980px;
    height: 552px;
    text-align: center;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin-right: 4px;
  }

  .demo:hover{
    display: block;
  }

  .video {
    pointer-events: none;
  }
</style>
