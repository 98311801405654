<template>
  <div>
    <ul class="ulName" id="navone">
      <li v-for="(m, index) in content" :key="m.id" class="licls">
        <span :class="{ active: index === current }" @click="doActive(index,m.id)">
         <font size="3"><b> {{ m.cp }}</b></font></span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import {QueryExamType} from '../api/data.js';
export default {
  data() {
    return {
      current: 0,
      content: [],
    };
  },
  created(){
    let that = this; //很重要
      QueryExamType().then(function(res){
        for(var i=0;i<res.data.length;i++)
        {
          var obj = new Object();
          obj.id = res.data[i].pkid;
          obj.cp = res.data[i].name;
          that.content.push(obj);
          if (i==0)
            that.$emit('refreshData',obj.id);
        }
      });
    
  },
  methods: {
    doActive: function (index,id) {
      this.current = index;
      this.$forceUpdate();
      this.$emit('refreshData',id);
      
    },
  },
  go_link(){
      var param="width="+window.screen.width * window.devicePixelRatio+",height="+window.screen.height * window.devicePixelRatio+",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no";
      const neww = this.$router.resolve({name: 'Papers', params: {}});
      window.open(neww.href,"云思模拟考场",param);
    }
}
</script>

<style lang="less" scoped>
#navone {
  display: inline;
  white-space: nowrap;
}
.licls {
  list-style: none;
  display: inline-block;
  margin: 5px 5px 10px 10px;
}
.licls span:hover {
  background-color: rgb(178, 186, 233);
  border-radius: 5px;
  cursor: pointer;
}

#navone li span {
  height: 100%;
  width: 100%;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}

.active {
  color: red;
  background-color: rgb(178, 186, 233);
}
</style>
