import { createStore } from 'vuex'

export default createStore({
  state: {
    
  },
  mutations: {
    //state中的token的初始值是false，表示未登录
    login(state,n){
      state.token=true;
      state.username=n;
      sessionStorage.setItem('isLogin',true);
      sessionStorage.setItem('name',n);  //用于全局展示是谁登录系统
    }
  },
  actions: {
  },
  modules: {
  },
  
})
