import request from '../config/index.js'

export function QueryMyDoTestPaper(obj){
    return request({
        //请求地址
        url:'member/manager/queryMyDoTestPaper.json',
        //请求方式
        method:'post',
        //post请求方式时用
        data:{
                userId:obj.userId,
                business_type:'1314'
        },
        //get请求方式时用
        // params:{
        //     id:'ff'
        // }
    });
}

//生成我的考卷
export function FormationMyTest(obj){
    return request({
        //请求地址
        url:'member/manager/updateMyTestPaper.json',
        //请求方式
        method:'post',
        data:{
            userId:obj.userId,
            testPaperId:obj.testPaperId
        },
       });
}


//查询考试试卷
export function queryTestPaperDtl(obj){
    return request({
    //请求地址
    url:'member/manager/queryTestPaperDtl.json',
    //请求方式
    method:'post',
    data:{
        userId:obj.userId,
        pkid:obj.testPaperId
    },
   });
}

//查询我的考试试卷
export function QueryMemberTestPaper(obj){
    return request({
    //请求地址
    url:'member/manager/queryMemberTestPaper.json',
    //请求方式
    method:'post',
    data:{
        userId:obj.userId,
        testPaperId:obj.testPaperId,
        TestPaperPkid:obj.TestPaperPkid
    },
   });
}
//查询题目
export function QueryNextProblem(obj){
    return request({
        //请求地址
        url:'member/manager/getNextProblem.json',
        //请求方式
        method:'post',
        data:{
            userId:obj.userId,
            testPaperId:obj.testPaperId,
            testPaperMemberId:obj.testPaperMemberId,
            problemId:obj.problemId
        },
       });
}

//交卷
export function HandInPaper(obj){
    return request({
        //请求地址
        url:'member/manager/updateMemberHandPaper.json',
        //请求方式
        method:'post',
        data:{
            actions:JSON.stringify(obj)
        },
       });
}

//考试结果查询
export function QueryMemberPaperResult(obj){
    return request({
        //请求地址
        url:'member/manager/queryMemberPaperResult.json',
        //请求方式
        method:'post',
        data:{
            userId:obj.userId,
            testPaperId:obj.testPaperId,
            testPaperMemberId:obj.testPaperMemberId
        },
       });
}

//查询知识库种类
export function QueryExamType(){
    return request({
        //请求地址
        url:'member/manager/queryExamTypeTree.json',
        //请求方式
        method:'post',
        data:{
            dtype:'OCta'
        },
       });
}

//查询知识库的试卷清单
export function QueryAvailableTestPaper(obj){
    return request({
        //请求地址
        url:'member/manager/queryAvailableTestPaper.json',
        //请求方式
        method:'post',
        data:{
            ministries:obj.ministries,
            userId:obj.userId,
            status:1518
        },
       });
}

//查询终端ID
export function QueryTerminal(obj){
    return request({
        //请求地址
        url:'member/manager/addTerminalInit.json',
        //请求方式
        method:'post',
        data:{
            mchId:obj.mchId,
            business_type:obj.business_type
        },
       });
}

//查询套餐
export function QueryMarketPackages(){
    return request({
        //请求地址
        url:'member/manager/queryMarketPackages.json',
        //请求方式
        method:'post',
        data:{
            business_type:'1314',
            status:'1518'
        },
       });
}

//获取支付预定单号
export function QueryPayNo(obj){
    return request({
        //请求地址
        url:'member/manager/addWebMyPayNo.json',
        //请求方式
        method:'post',
        data:{
            actions:'SeqPayNo',
            payChannel:'aliPay',
            business_type:'1314',
            userId:obj.userId,
            marketId:obj.marketId,
            moneys:obj.moneys,
            times:obj.times,
            status:'1543',
            subjects:'云思模拟考场'
        },
       });
}

//查询支付状态
export function QueryWebMyPay(obj){
    return request({
        //请求地址
        url:'member/manager/queryWebMyPay.json',
        //请求方式
        method:'post',
        data:{
            payno:obj.payno,
            userId:obj.userId
        },
       });
}

//查询我支付的定单
export function QueryMySubs(obj){
    return request({
        //请求地址
        url:'member/manager/queryMemberSubs.json',
        //请求方式
        method:'post',
        data:{
            business_type:'1314',
            userId:obj.userId
        },
       });
}